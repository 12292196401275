const paymentForm = document.querySelector('#pte-new-subscription-form');
if (paymentForm) {
  const stripe = Stripe(paymentForm.dataset.talk);

  let elements;

  initialize();

  paymentForm.addEventListener('submit', handleSubmit);

  const updatePaymentIntent = (paymentForm) => {
    const body = {
      recurring: paymentForm.querySelector('input[name="recurring"]')?.checked,
    };
    fetch('/subscriptions/update_payment_intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  };

  const recurringInput = document.querySelector('input[name="recurring"]');
  if (recurringInput) {
    updatePaymentIntent(paymentForm);
    recurringInput.addEventListener('change', () =>
      updatePaymentIntent(paymentForm)
    );
  }

  // Fetches a payment intent and captures the client secret
  async function initialize() {
    const clientSecret = document.getElementById('stripe_token').value;

    const appearance = {
      theme:
        document.documentElement.classList.contains('dark') ||
        window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'night'
          : 'stripe',
    };
    elements = stripe.elements({ appearance, clientSecret });

    const pteUserContext = JSON.parse(
      document.body.dataset.pteUserContext || '{}'
    );
    const paymentElementOptions = {
      defaultValues: {
        billingDetails: {
          name: pteUserContext.name,
          email: pteUserContext.email,
        },
      },
    };

    const paymentElement = elements.create('payment', paymentElementOptions);
    paymentElement.mount('#payment-element');

    paymentElement.on('ready', function () {
      const checkoutButton = document.querySelector('.stripe-card-box button');
      setTimeout(() => {
        checkoutButton.classList.remove('opacity-0');
      }, 200);
    });
    paymentElement.on('change', function (event) {
      const cardNameContainer = document.querySelector('.stripe-card-name');
      const cardNameInput = document.querySelector('input.card-name');
      const isNameRequired = event.value.type == 'card';
      const checkoutButton = document.querySelector('.stripe-card-box button');

      cardNameContainer.classList.toggle('disabled', !isNameRequired);
      cardNameInput.required = isNameRequired;
      checkoutButton.disabled = !event.complete;
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const locationOrigin = `${window.location.protocol}//${window.location.host}`;
    const cardName = document.querySelector('.card-name').value;
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${locationOrigin}/subscriptions/success`,
        payment_method_data: {
          billing_details: {
            name: cardName,
          },
        },
      },
    });

    if (error) {
      showMessage(error.message);
    }

    setLoading(false);
  }

  // ------- UI helpers -------

  function showMessage(messageText) {
    const messageContainer = document.querySelector('#payment-message');
    messageContainer.classList.remove('hidden');
    messageContainer.textContent = messageText;

    setTimeout(() => {
      messageContainer.classList.add('hidden');
      messageContainer.textContent = '';
    }, 10000);
  }

  // Show a spinner on payment submission
  function setLoading(isLoading) {
    const button = document.querySelector('.stripe-card-box button');
    const spinner = document.querySelector('#spinner');
    const buttonText = button.querySelector('#button-text');

    button.disabled = isLoading;
    spinner.classList.toggle('hide-important', !isLoading);
    buttonText.classList.toggle('hide', isLoading);
  }
}
